import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import App from './App'
import VipLevels from './modules/pages/VipLevels'
import SlotPage from './modules/pages/SlotPage'
import TopHeaderNav from './modules/snippets/TopHeaderNav'
import MainNav from './modules/snippets/MainNav'
import SideContainer from './modules/snippets/SideContainer'

//CSS
import './style/style.css'
import './style/loading.css'
import './style/fonts.css'

export const promoUrl = process.env.REACT_APP_INDEX_PROMO_URL
export const apiURL = process.env.REACT_APP_API_JSON_URL
export const dataUrl = process.env.REACT_APP_DATA_JSON_URL

export const routes = [
  { path: '/', element: <App /> },
  { path: '/slots/', element: <SlotPage /> },
  { path: '/vip/', element: <VipLevels /> },
]

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <main className='main-container'>
      <TopHeaderNav/>
      <div className='main-body'>
        <MainNav/>
        <div className='main-body-section'>
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Router>
        <SideContainer/>
        </div>
        
      </div>
    </main>
  </>
)
