import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import TopHeaderNav from '../snippets/TopHeaderNav'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { vipItems } from '../data/vip-levels-data'
function VipCard({ level, imgSrc, isActive }) {
  return (
    <li className={`vip-card ${isActive ? 'active-card' : ''}`}>
      <img src={`/images/vip-levels/${imgSrc}.webp`} alt={`${level} Tier`} />
      <h3>{level} Tier</h3>
      <table className="vip-table">
        <tbody>
          <tr>
            <td>Montly Deposit</td>
            <td>20,000</td>
          </tr>
          <tr>
            <td>Montly Deposit</td>
            <td>20,000</td>
          </tr>
          <tr>
            <td>Montly Deposit</td>
            <td>20,000</td>
          </tr>
          <tr>
            <td>Montly Deposit</td>
            <td>20,000</td>
          </tr>
        </tbody>
      </table>
    </li>
  )
}
export default function VipLevels() {
  const [activeIndex, setActiveIndex] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    afterChange: (current) => setActiveIndex(current),

    centerPadding: '0px',
  }

  return (
    <>
      <Helmet>
        <title>VIP Levels - FGH - Your One-Stop Gaming Site</title>
      </Helmet>
      <TopHeaderNav />
      <section className="main-slider">
        <img
          src="/images/banners/Daily_Wins_1903x550.png"
          alt="Daily Wins Banner"
        />
      </section>
      <div className="vip-container">
        <h2>Current VIP Levels</h2>
        <Slider {...settings}>
          {vipItems.map((card, index) => (
            <VipCard key={card.id} {...card} isActive={index === activeIndex} />
          ))}
        </Slider>
      </div>
    </>
  )
}


