import React from 'react'

export default function SideContainer() {
  return (
    <>
        <div className='side-bar-container'>
            
        </div>
    </>
  )
}
