import React from 'react'
import { Helmet } from 'react-helmet'
import MainSlider from './snippets/MainSlider';
import AnnouncementTicker from './snippets/Announcement';
import TopGameList from './snippets/TopGameList';

export const currentPath = window.location.pathname;
export const currentDomain = window.location.origin;

export default function IndexPage () {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FGH</title>
        <link rel="icon" href="/images/fav.webp" sizes="32x32" />
        <meta name="description" content="ddd"/>
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <section className='main-body-content'>
        <MainSlider/>
        <AnnouncementTicker/>
        <TopGameList provider="jili" />
      </section>
    </>
  )
}