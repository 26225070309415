import React from 'react'
import Index from './modules/Index'

export const tokenURL = process.env.REACT_APP_TOKEN_URL;
export const wlpLaunchURL = process.env.REACT_APP_WLP_LAUNCH_URL;
export const httpsLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;
export const apiJSONURL = process.env.REACT_APP_API_JSON_URL;
export const totalFrames = 77; // The last frame number

export default function App() {
  return (
    <>
      <Index />
    </>
  )
}
