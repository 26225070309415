import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { tokenURL, httpsLaunchURL, apiJSONURL } from "../../App";
import ColorThief from "colorthief";

const TopGameList = ({ limit }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const totalFrames = 77;
  const [gameStart, setGameStart] = useState();
  const [gameEnd, setGameEnd] = useState();
  const [isUnique, setIsUnique] = useState(false);
  const [isProvider, setIsProvider] = useState(false);
  const [gamePopupWindow, setGamePopupWindow] = useState(null);
  const [provider, setProvider] = useState(null);
  const imageRef = useRef(null);
  const isMobile = window.innerWidth <= 840;
  const [backgroundColors, setBackgroundColors] = useState({});

  useEffect(() => {
    const checkPopupClosed = () => {
      if (gamePopupWindow && gamePopupWindow.closed) {
        setGameEnd(Date.now());
        console.log("Popup window closed.");
        setGamePopupWindow(null);
      }
    };

    const interval = setInterval(checkPopupClosed, 1000);
    return () => clearInterval(interval);
  }, [gamePopupWindow]);

  const triggerCheck = (game) => {
    const rgJson = JSON.parse(localStorage.getItem("recentGames"));
    setGameStart(Date.now());
    if (Array.isArray(rgJson)) {
      const gameIds = rgJson.map((game) => game.gameIdN);
      if (game.provider !== "fachai") {
        setIsProvider(true);
      }
      if (!gameIds.includes(game.gameIdN)) {
        setIsUnique(true);
        console.log(`Game with ID ${game.gameIdN} does not exist.`);
      }
    } else {
      console.error("recentGames is not an array");
    }
  };

  useEffect(() => {
    const fetchTopGames = async () => {
      try {
        const response = await axios.post(
          apiJSONURL,
          `cmd=getTopGames&domain=sg8`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const jsonData = response.data;
        let transformedData = {};

        for (let key in jsonData) {
          if (jsonData.hasOwnProperty(key)) {
            let splitValues = jsonData[key]
              .split("|")
              .map((value) => value.trim());
            let gameImg;
            if (splitValues[3]) {
              gameImg =
                splitValues[0] === "PGSOFT" ||
                splitValues[0] === "NEXTSPIN" ||
                splitValues[0] === "BOOONGO" ||
                splitValues[0] === "PLAYSON"
                  ? `https://sg8.casino/json/resources/images/WLP/${splitValues[3].slice(0, 2)}/${
                      splitValues[3]
                    }.jpg`
                  : `https://sg8.casino/json/resources/images/WLP/${splitValues[3].slice(
                      0,
                      2
                    )}/200x200/${splitValues[3]}.jpg`;
            }
            transformedData[key] = {
              provider: splitValues[0],
              gameId: splitValues[2],
              gameName: splitValues[1],
              gameIdN: splitValues[3],
              gameImg: gameImg,
            };
          }
        }

        setGames(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch games:", error);
        setLoading(false);
      }
    };
    fetchTopGames();
  }, []);

  const saveRecentGame = (game) => {
    let recentGames = JSON.parse(localStorage.getItem("recentGames")) || [];
    recentGames = recentGames.filter((g) => g.gameId !== game.gameId);
    recentGames.unshift(game);
    if (recentGames.length > 5) {
      recentGames.pop();
    }
    localStorage.setItem("recentGames", JSON.stringify(recentGames));
  };

  const launchGame = async (game) => {
    if (!document.body.classList.contains("isLoggedIn")) {
      setPopupMessage("Please register and login to play the game.");
      setIsPopupOpen(true);
      return;
    }
    setProvider(game.provider);
    setLaunching(true);
    setCurrentFrame(19);
    setAnimationComplete(false);
    if (imageRef.current) {
      imageRef.current.classList.remove("pop");
    }

    try {
      const encryptedUsername = encodeURIComponent(
        localStorage.getItem("encryptedUsername")
      );

      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${game.gameIdN}&t=transfer&m=mobile`
      );
      const sessionToken = tokenResponse.data.token;

      const lang = "en";
      const mobileIndicator = isMobile ? "1" : "";
      const url = `${httpsLaunchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`;
      console.log("Launching game with URL:", url);

      const launchGameAfterAnimation = () => {
        triggerCheck(game);
        const popupWindow = window.open(
          url,
          "_blank",
          "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
        );
        setGamePopupWindow(popupWindow);
        if (popupWindow) {
          popupWindow.focus();
        } else {
          setPopupMessage("Please disable your pop-up blocker and try again.");
          setIsPopupOpen(true);
        }
        setLaunching(false);
        saveRecentGame(game);
      };

      if (animationComplete) {
        launchGameAfterAnimation();
      } else {
        setTimeout(() => {
          launchGameAfterAnimation();
        }, (totalFrames - currentFrame) * 30);
      }
    } catch (error) {
      console.error("Failed to launch game:", error);
      setLaunching(false);
    }
  };

  const handleImageLoad = async (event, gameId) => {
    const img = event.target;
    const colorThief = new ColorThief();
    if (img.complete) {
      try {
        const color = colorThief.getColor(img);
        setBackgroundColors((prevColors) => ({
          ...prevColors,
          [gameId]: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
        }));
      } catch (error) {
        console.error("Color extraction failed:", error);
      }
    } else {
      img.addEventListener("load", async () => {
        try {
          const color = colorThief.getColor(img);
          setBackgroundColors((prevColors) => ({
            ...prevColors,
            [gameId]: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
          }));
        } catch (error) {
          console.error("Color extraction failed:", error);
        }
      });
    }
  };

  if (loading) {
    return (
      <section className="loadingGames">
        <img
          src="/images/sg8.gif"
          style={{ width: "200px" }}
          alt="Loading..."
        />
      </section>
    );
  }

  return (
    <div className="gameListWrapper">
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
      >
        <div
          className="popupError"
          style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}
        >
          <img src="/images/promo-mobile-icon.webp" alt="Promo" />
          <div className="content">{popupMessage}</div>
          <div className="actions">
            <button
              className="yellow-btn"
              onClick={() => setIsPopupOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      {launching && (
        <div className="overlay">
          <div className="loadingBar">
            <div className={`spinner ${animationComplete ? "pop" : ""}`}>
              <img
                ref={imageRef}
                src={`/images/frameLoading/sg8logo_${String(
                  currentFrame
                ).padStart(5, "0")}.png`}
                alt="Loading..."
              />
            </div>
          </div>
        </div>
      )}
      {games ? (
        Object.keys(games)
          .slice(0, limit)
          .map((key, index) => {
            const game = games[key];
            const rank = index;
            return (
              <div
                key={index}
                style={{
                  overflowX: "hidden",
                  backgroundColor: backgroundColors[game.gameId] || "#f0f0f0",
                }}
                className="gameWrapper"
              >
                <div
                  className="game"
                  onClick={() => (isMobile ? launchGame(game) : null)}
                >
                  <div className="rank-circle">{rank + 1}</div>
                  <div className="gameImg">
                    <img
                      alt={`${game.gameName} ${game.provider}`}
                      src={game.gameImg}
                      onLoad={(event) => handleImageLoad(event, game.gameId)}
                      style={{ opacity: 1, transition: "opacity 0.5s" }}
                      crossOrigin="anonymous"
                    />
                  </div>
                  {!isMobile && (
                    <div className="gameDetails">
                      <div className="gameName">{game.gameName}</div>
                      <button onClick={() => launchGame(game)}>Play Now</button>
                    </div>
                  )}
                </div>
              </div>
            );
          })
      ) : (
        <section className="loadingGames">
          <div>
            <img src="/images/sg8-icon.webp" style={{ width: "60px" }} />
            <p>No games found for selected filter.</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default TopGameList;
