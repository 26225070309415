import React, { useState, useEffect } from "react";

export default function AnnouncementTicker() {
  const [current, setCurrent] = useState(0);
  const newsItems = [
    { id: 1, winner: "ANOBA***3232", amount: "₱300,000" },
    { id: 2, winner: "JANE***1234", amount: "₱500,000" },
    { id: 3, winner: "MARC***5678", amount: "₱250,000" },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % newsItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [newsItems.length]);

  return (
    <div className="announcement-ticker">
      <div className="static-label"><img src="/images/icon/news-icons.webp"/> Lucky Winners :</div>
      <div className="dynamic-content">
        {newsItems.map((item, index) => (
          <div key={item.id} className={`news-item ${index === current ? "active" : "hidden"}`}>
            <span className="winner-id">{item.winner}</span> total winnings of{" "}
            <span className="winning-amount">{item.amount}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
