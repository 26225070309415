import React, { useState } from 'react'

export default function TopHeaderNav() {
  return (
    <>
      <div className="header-nav-container">
        <section className="header-nav-content">
          <a href='/' className="header-nav-logo"> <img src="/images/icon/fgh-logo.webp" alt="FGH Logo" /> </a>
        </section>
      </div>
    </>
  )
}
