import React from 'react';

export default function MainNav() {
  const currentPath = window.location.pathname;

  const navigationItems = [
    { href: '', src: '/images/icon/promotion-icons.webp', label: 'Promotions', alt: 'Promotions Icon', className: 'nav-item-promotions' },
    { href: '/', src: '/images/icon/home-icons.webp', label: 'Home', alt: 'Home Icon', className: 'nav-item-home' },
    { href: '/slots/', src: '/images/icon/slots-icons.webp', label: 'Slots', alt: 'Slots Icon', className: 'nav-item-slots' },
    { href: '', src: '/images/icon/live-dealer-icons.webp', label: 'Live Dealer', alt: 'Live Dealer Icon', className: 'nav-item-live-dealer' },
    { href: '', src: '/images/icon/sports-icons.webp', label: 'Sportsbook', alt: 'Sportsbook Icon', className: 'nav-item-sportsbook' },
    { href: '', src: '/images/icon/fishing-icons.webp', label: 'Fishing', alt: 'Fishing Icon', className: 'nav-item-fishing' },
    { href: '', src: '/images/icon/bingo-icons.webp', label: 'Bingo', alt: 'Bingo Icon', className: 'nav-item-bingo' },
  ];

  const secondaryItems = [
    { href: '', src: '/images/icon/news-icons.webp', label: 'News', alt: 'News Icon', className: 'nav-item-news' },
    { href: '', src: '/images/icon/vip-icons.webp', label: 'VIP', alt: 'VIP Icon', className: 'nav-item-vip' },
    { href: '', src: '/images/icon/chat-support-icons.webp', label: 'Chat Support', alt: 'Chat Support Icon', className: 'nav-item-chat-support' },
    { href: '', src: '/images/icon/download-app-icons.webp', label: 'Download App', alt: 'Download App Icon', className: 'nav-item-download-app' },
  ];

  return (
    <>
      <nav className='main-navigation'>
        <ul>
          {navigationItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className={`${item.className} ${currentPath === item.href ? 'active' : ''}`}
            >
              <li>
                <img src={item.src} alt={item.alt} />
                {item.label}
              </li>
            </a>
          ))}
        </ul>
        <div className='navigation-divider'></div>
        <ul>
          {secondaryItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className={`${item.className} ${currentPath === item.href ? 'active' : ''}`}
            >
              <li>
                <img src={item.src} alt={item.alt} />
                {item.label}
              </li>
            </a>
          ))}
        </ul>
      </nav>
    </>
  );
}
