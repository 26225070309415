import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const MainSlider = () => {
  const slides = [
    { id: 1, image: "/images/banners/black-friday-sale-sg8casino.webp", className: "banners", altxt: "Black Friday Sale Banner 1" },
    { id: 2, image: "/images/banners/black-friday-sale-sg8casino.webp", className: "banners", altxt: "Black Friday Sale Banner 2" },
    { id: 3, image: "/images/banners/black-friday-sale-sg8casino.webp", className: "banners", altxt: "Black Friday Sale Banner 3" },
    { id: 4, image: "/images/banners/black-friday-sale-sg8casino.webp", className: "banners", altxt: "Black Friday Sale Banner 4" },
  ];

  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Loop slides infinitely
    speed: 500, // Transition speed (ms)
    slidesToShow: 1, // Number of slides visible
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: false, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed (optional)
    arrows: true, // Remove arrows
  };
  

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id} className={slide.className}>
            <img src={slide.image} alt={slide.altxt} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MainSlider;
