export const vipItems = [
  {
    id: '0',
    level: 'Emerald',
    imgSrc: 'emerald',
    monthlyDeposit: '20,000',
    monthlyTurnover: '500,000',
  },
  ,
  {
    id: '1',
    level: 'Sapphire',
    imgSrc: 'sapphire',
    monthlyDeposit: '20,000',
    monthlyTurnover: '500,000',
  },
  {
    id: '2',
    level: 'Ruby',
    imgSrc: 'ruby',
    monthlyDeposit: '20,000',
    monthlyTurnover: '500,000',
  },
  {
    id: '3',
    level: 'Diamond',
    imgSrc: 'diamond-tier',
    monthlyDeposit: '20,000',
    monthlyTurnover: '500,000',
  },
  {
    id: '4',
    level: 'Alexandrite',
    imgSrc: 'alexandrite-tier',
    monthlyDeposit: '20,000',
    monthlyTurnover: '500,000',
  },
]
